@import '../../styles/variables', './dark.module', './light.module', './classic.module', './minimalist.module';

// DESKTOP
@media screen and (min-width: $dMinW) {
  .minimalist,
  .classic,
  .container {
    height: $desktopHeight;
    max-width: $desktopActivityContainerWidth;
    width: $desktopActivityContainerWidth;
  }

  .icon {
    height: 35px;
    width: 35px;
  }

  .wrapper {
    flex-direction: row;
    width: 95%;
  }
}

// TABLET
@media screen and (min-width: $tMinW) and (max-width: $tMaxW) {
  .minimalist,
  .classic,
  .container {
    height: $tabletHeight;
    max-width: $tabletActivityContainerWidth;
    width: $tabletActivityContainerWidth;
  }

  .icon {
    height: 23px;
    width: 23px;
  }

  .wrapper {
    flex-direction: row;
    width: 95%;
  }
}

// MOBILE
@media screen and (max-width: $mMaxW) {
  .minimalist,
  .classic,
  .container {
    flex-direction: column;
    height: fit-content;
    width: 100%;
  }

  .icon {
    height: 23px;
    width: 23px;
  }

  .wrapper {
    flex-direction: column;
    height: 140px;
    min-height: 140px;
    padding-bottom: 20px;
    width: 70%;
  }
}

.container {
  align-items: space-between;
}

.wrapper {
  justify-content: space-around;
  margin: auto;
}

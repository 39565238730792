@import '../../styles/variables', './dark.module', './light.module', './classic.module', './minimalist.module';

@media screen and (min-width: $dMinW) {
  .minimalist,
  .classic,
  .container {
    height: $desktopChartContainerHeight;
    max-height: $desktopChartContainerHeight;
    overflow: hidden;
    width: $desktopChartContainerWidth;
  }

  .chart {
    height: $desktopChartContainerHeight;
    width: $desktopChartContainerWidth;
  }

  .chart > div {
    width: 98%;
  }

  .chart * {
    overflow: visible;
  }
}
  
@media screen and (min-width: $tMinW) and (max-width: $tMaxW) {
  .minimalist,
  .classic,
  .container {
    height: $tabletChartContainerHeight;
    max-height: $tabletChartContainerHeight;
    overflow: hidden;
    width: $tabletChartContainerWidth;
  }

  .chart {
    height: $tabletChartContainerHeight;
    margin-top: 10px;
    width: $tabletChartContainerWidth;
  }

  .chart * {
    overflow: visible;
  }
}

@media screen and (max-width: $mMaxW) {
  .minimalist,
  .classic,
  .container {
    height: $mobileChartContainerHeight;
    overflow: hidden;
    width: $mobileChartContainerWidth;
  }

  .chart {
    height: $mobileChartContainerHeight;
    width: $mobileChartContainerWidth;
  }
}

.container {
  justify-content: space-between;
  width: 100%;
}

.chart {
  position: auto;
}

@media screen and (min-width: $dMinW) {
  .minimalist {
    width: 20%;

    .container {
      font-size: 12px;
      letter-spacing: -1px;
    }
  }
}

@media screen and (min-width: $tMinW) and (max-width: $tMaxW) {
  .minimalist {
    width: 20%;
    
    .container {
      font-size: 10px;
      letter-spacing: -0.6px;
    }
  }
}

@media screen and (max-width: $mMaxW) {
  .minimalist {
    .wrapper {
      width: 90px;
    }
  }
}

.minimalist {
  .score {
    background-color: $lightTertiaryWhite;
  }
}

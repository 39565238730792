@import '../../styles/variables';

@media screen and (min-width: $dMinW) {
  .container,
  .wrapper {
    flex-direction: row;
    width: 100%;
    height: 100%;
  }
}

@media screen and (min-width: $tMinW) and (max-width: $tMaxW) {
  .container,
  .wrapper {
    flex-direction: row;
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: $mMaxW) {
  .minimalist,
  .classic {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }

  .container {
    align-items: center;
    flex-direction: column;
    min-width: 100%;
    width: 100%;
    height: 100%;
  }

  .wrapper {
    width: 100%;
    height: 100%;
  }

  .container input {
    height: 99%;
  }
}

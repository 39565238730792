.classic.light {
  .icon:hover path {
    fill: $classicLightRain;
  }

  .five {
    background-color: $classicLightGreatGreen;
  }
    
  .four {
    background-color: $classicLightGoodGreen;
  }

  .three {
    background-color: $classicLightYellow;
  }

  .two {
    background-color: $classicLightOrange;
  }

  .one {
    background-color: $classicLightRed;
  }
}

.minimalist.light {
  .icon:hover path {
    fill: $minimalistLightRain;
  }

  .score {
    background-color: $minimalistLightGrey;
  }

  .five {
    background-color: $minimalistLightGreatGreen;
  }
  
  .four {
    background-color: $minimalistLightGoodGreen;
  }
  
  .three {
    background-color: $minimalistLightYellow;
  }
  
  .two {
    background-color: $minimalistLightOrange;
  }
  
  .one {
    background-color: $minimalistLightRed;
  }
}

@import '../../styles/variables', './dark.module', './light.module';

@media screen and (min-width: $dMinW) {
  .container {
    font-size: 14px;
    justify-content: left;
    max-width: 350px;
  }

  .icon,
  .iconWrapper {
    height: 35px;
  }

  .icon {
    width: 35px;
  }

  .active {
    font-weight: 700;
  }

  .active, .inactive {
    padding: 0px 15px 0px 10px;
    min-width: 75px;
    width: auto;
  }
}
  
@media screen and (min-width: $tMinW) and (max-width: $tMaxW) {
  .container {
    font-size: 13px;
    justify-content: left;
    max-width: 330px;
  }

  .icon,
  .iconWrapper {
    height: 25px;
  }

  .icon {
    width: 25px;
  }

  .active, .inactive {
    padding: 0px 15px 0px 10px;
    min-width: 70px;
    width: auto;
  }
}

@media screen and (max-width: $mMaxW) {
  .container {
    font-size: 14px;
    justify-content: space-around;
    width: 100%;
  }

  .icon,
  .iconWrapper {
    height: 27px;
  }

  .iconWrapper {
    margin-right: 3px;
  }

  .icon {
    width: 27px;
  }

  .active, .inactive {
    flex-direction: row;
    justify-content: center;
    padding: 3px 5px;
    width: 40%;
  }
}

.container {
  align-items: center;
  cursor: pointer;
  flex-direction: row;
  height: fit-content;
}

.active, .inactive {
  align-items: center;
  flex-direction: row;
  justify-content: center;
}


@import '../../styles/variables';

@media screen and (min-width: $dMinW) {
  .container {
    height: $desktopHeight - 110px;
    width: 100%;
  }

  .error {
    font-size: 20px;
    margin-bottom: 15px;
  }

  .attribution {
    font-size: 12px;
    margin-right: 5px;
  }
}

@media screen and (min-width: $tMinW) and (max-width: $tMaxW) {
  .container {
    height: $tabletHeight - 75px;
    margin: auto;
    overflow: hidden;
    width: 400px;
  }

  .error {
    font-size: 14px;
    margin-bottom: 20px;
  }

  .attribution {
    font-size: 11px;
  }
}

@media screen and (max-width: $mMaxW) {
  .container {
    height: $tabletHeight - 75px;
    margin: auto;
    margin-bottom: 50px;
    overflow: hidden;
    width: 95%;
  }

  .error {
    font-size: 14px;
    margin-bottom: 3px;
  }

  .attribution {
    font-size: 10px;
    margin-top: auto;
  }
}

.container {
  align-items: center;
  justify-content: center;
  text-align: center;
}

.classic {
  color: $classicLightRain;
  font-family: $classicFontFamily, sans-serif;

  .attribution {
    color: $lightTertiaryWhite;
  }

  .error {
    color: $classicLightRed;
  }
}

.minimalist {
  color: $minimalistLightRain;
  font-family: $minimalistFontFamily, sans-serif;

  .error {
    color: $minimalistLightRed;
  }
}

.attribution {
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  letter-spacing: -.5px;

  a {
    margin-left: 5px;
  }

  a:visited {
    color: inherit;
  }
}

@import '../../styles/variables';

@media screen and (min-width: $dMinW) {
  .container {
    height: $desktopHeight + $htmlPadding*2;
    width: $desktopWidth + $htmlPadding*2;
  }
  
  .wrapper {
    height: $desktopHeight;
  }
}

@media screen and (min-width: $tMinW) and (max-width: $tMaxW) {
  .container {
    height: $tabletHeight + $htmlPadding*2;
    width: $tabletWidth + $htmlPadding*2;
  }
  
  .wrapper {
    height: $tabletHeight;
  }
}

@media screen and (max-width: $mMaxW) {
  .container {
    height: fit-content;
    width: 100%;
  }
}

.container {
  margin: auto;
}

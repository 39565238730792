@import '../../styles/variables', './dark.module', './light.module', './classic.module', './minimalist.module';

@media screen and (min-width: $dMinW) {
  .container {
    padding: 5px 15px;
  }
}

@media screen and (min-width: $tMinW) and (max-width: $tMaxW) {
  .container {
    height: 60px;
    margin: 0;
    max-height: 60px;
    padding: 0 10px;
  }

  .action {
    font-size: 11px;
  }

  .powered {
    font-size: 13px;
  }
}

// MOBILE
@media screen and (max-width: $mMaxW) {
  .container {
    flex-direction: column;
    max-height: 90px;
    padding: 0 20px 5px;
  }

  .icon {
    height: 25px;
    width: 25px;
  }


  .wrapper {
    flex-direction: column;
    height: 160px;
    width: 70%;
  }

  .action {
    font-size: 13px;
    letter-spacing: -0.2px;
  }

  .powered {
    font-size: 14px;
    margin-top: 10px;
  }
}

.container {
  flex-grow: 1;
  justify-content: flex-end;
}

.action {
  display: inline;
  line-height: 1;
  margin-bottom: auto;
}

.powered {
  justify-self: flex-end;
  text-align: right;
  cursor: default;
  width: fit-content;
  margin-left: auto;
}

.link {
  display: inline;
}

@import '../../styles/variables';

.container {
  font-size: 12px;
  font-style: italic;
  font-weight: 500;
  height: 25px;
  justify-content: center;
  margin-left: 15px;
  width: auto;
}

.classic {
  color: $classicLightRed;
}

.minimalist {
  color: $minimalistLightRed;
}

@import '../../styles/variables', './dark.module', './light.module';

@media screen and (min-width: $dMinW) {
  .container {
    flex-direction: row;
    height: $desktopHeight;
    padding: $htmlPadding;
    width: $desktopWidth;
  }
  
  .wrapper {
    height: $desktopHeight;
    justify-content: space-between;
    width: $desktopChartContainerWidth;
  }
}


@media screen and (min-width: $tMinW) and (max-width: $tMaxW) {
  .container {
    flex-direction: row;
    height: $tabletHeight;
    padding: $htmlPadding;
    width: $tabletWidth;
  }
  
  .wrapper {
    height: $tabletHeight;
    justify-content: space-between;
    width: $tabletChartContainerWidth;
  }
}

@media screen and (max-width: $mMaxW) {
  .container {
    flex-direction: column;
    height: fit-content;
    padding: $htmlPadding 0 0;
  }

  .wrapper {
    height: 345px;
    justify-content: flex-start;
  }
}

@import '../../styles/variables';

@media screen and (min-width: $dMinW) {
  .container {
    height: $desktopHeight - 90px;
    width: 100%;
  }

  .fetching {
    height: 60px;
  }
}

@media screen and (min-width: $tMinW) and (max-width: $tMaxW) {
  .container {
    height: $tabletHeight - 75px;
    margin: auto;
    overflow: hidden;
    width: 400px;
  }

  .fetching {
    height: 45px;
  }
}

@media screen and (max-width: $mMaxW) {
  .container {
    height: $tabletHeight - 75px;
    margin: auto;
    overflow: hidden;
    width: 95%;
  }

  .fetching {
    height: 45px;
    max-width: 90%;
  }
}

.container {
  align-items: center;
  justify-content: space-between;
  line-height: 30px;
  text-align: center;
}

@import '../../styles/variables', './dark.module', './light.module', './classic.module', './minimalist.module';

@media screen and (min-width: $dMinW) {
  .container {
    height: 75px;
    margin: 30px 15px;
  }

  .scoreWrapper {
    margin-top: 25px;
    max-width: 100%;
    width: 100%;
  }

  .date {
    min-height: 16px;
  }

  .score {
    font-size: 19px;
    letter-spacing: -0.5px;
  }

  .weatherFor {
    font-size: 14.5px;
    margin: 0 5px;
  }
}

// TABLET - scale by 75%
@media screen and (min-width: $tMinW) and (max-width: $tMaxW) {
  .container {
    height: 60px;
    min-height: 60px;
    width: 100%;
  }

  .date {
    font-size: 11.5px;
    margin-left: 5px;
    min-height: 16px;
  }

  .scoreWrapper {
    font-size: 13px;
    margin: 15px 5px 0;
    margin-top: 15px;
  }

  .score {
    font-size: 15px;
    letter-spacing: -0.5px;
  }
  
  .weatherFor {
    font-size: 12px;
    margin: 0 4px;
  }
}

// MOBILE
@media screen and (max-width: $mMaxW) {
  .container {
    height: 60px;
    margin: 10px auto;
    width: 100%;
  }

  .date {
    font-size: 13px;
    margin-right: 90px;
    min-height: 15px;
    text-align: right;
    width: 95%;
  }

  .scoreWrapper {
    font-size: 18px;
    justify-content: center;
    margin: 15px 15px 0;
    min-height: 30px;
  }

  .score {
    font-size: 20px;
    letter-spacing: -0.5px;
  }
  
  .weatherFor {
    font-size: 18px;
    margin: 0 8px;
  }
}

.container {
  position: relative;
}

.date {
  font-weight: 300;
  letter-spacing: -0.4px;
}

.scoreWrapper {
  align-items: baseline;
  flex-direction: row;
  flex-wrap: nowrap;
  font-weight: normal;
  height: fit-content;
  white-space: nowrap;
}

.scoreWrapperPlaceholder {
  justify-content: center;
}

.weatherFor {
  letter-spacing: -1px;
}

$lightPrimaryWhite: #fff;
$lightSecondaryWhite: #f0f0f0;
$lightTertiaryWhite: #c4c4c4;
$darkPrimaryBlack: #292a2d;
$darkSecondaryBlack: #35373b;
$darkTertiaryBlack: #4d5056;

$lightInputFadeIn: rgba(255, 255, 255, 0);
$lightInputFadeOut: rgba(255, 255, 255, 1);
$darkInputFadeIn: rgba(41, 42, 45, 0);
$darkInputFadeOut: rgba(41, 42, 45, 1);


$classicFontFamily: 'Roboto';
$classicHandwriting: 'Caveat';
$minimalistFontFamily: 'Montserrat';
$minimalistHandwriting: 'Josefin Slab';


$classicLightBlack: #313131;
$classicLightRed: #e74c38;
$classicLightRain: #6790bb;
$classicLightSnow: #35d7e4;
$classicLightGreatGreen: #2a0;
$classicLightGoodGreen: #71cb5e;
$classicLightYellow: #f6d24f;
$classicLightOrange: #f69a4f;


$classicDarkWhite: rgba(255, 255, 255, 0.87);
$classicDarkRed: #cb0025;
$classicDarkRain: #6069f1;
$classicDarkSnow: #bcf6f6;
$classicDarkGreatGreen: #549640;
$classicDarkGoodGreen: #89c577;
$classicDarkYellow: #ecec13;
$classicDarkOrange: #da7b52;


$minimalistLightPrimaryBlack: #202020;
$minimalistLightSecondaryBlack: #555;
$minimalistLightTertiaryBlack: #8f8f8f;
$minimalistLightGrey: #d5d5d5;
$minimalistLightRed: #ca5b54;
$minimalistLightRain: #81aece;
$minimalistLightSnow: #9bd6d3;
$minimalistLightGreatGreen: #76af7d;
$minimalistLightGoodGreen: #b6d4ba;
$minimalistLightYellow: #fad78b;
$minimalistLightOrange: #f9b472;

$minimalistDarkWhite: rgba(255, 255, 255, 0.87);
$minimalistDarkSecondaryBlack: #555;
$minimalistDarkTertiaryBlack: #8f8f8f;
$minimalistDarkGrey: #d5d5d5;
$minimalistDarkRed: #ca5b54;
$minimalistDarkRain: #81aece;
$minimalistDarkSnow: #a8d5d5;
$minimalistDarkGreatGreen: #76af7d;
$minimalistDarkGoodGreen: #b6d4ba;
$minimalistDarkYellow: #fad78b;
$minimalistDarkOrange: #f9b472;




// WIDGET DIMENSIONS
// UPDATE CHART RESIZE TRIGGER POINTS WHEN UPDATING ANY HEIGHT/WIDTH VARS
$desktopHeight: 400px;
$desktopWidth: 860px;

$tabletHeight: $desktopHeight * 0.75;
$tabletWidth: $desktopWidth * 0.75;

$htmlPadding: 20px;

// BREAKPOINTS
$dMinW: $desktopWidth + ($htmlPadding * 2);

$tMaxW: $desktopWidth + ($htmlPadding * 2) - 1;
$tMinW: $tabletWidth + ($htmlPadding * 2);

$mMaxW: $tabletWidth + ($htmlPadding * 2) - 1;

// CONTAINER DIMENSIONS
$desktopActivityContainerHeight: 100%;
$desktopActivityContainerWidth: 273px;
$desktopInputContainerHeight: 90px;
$desktopInputContainerWidth: $desktopWidth - $desktopActivityContainerWidth;
$desktopChartContainerHeight: $desktopHeight - $desktopInputContainerHeight;
$desktopChartContainerWidth: $desktopInputContainerWidth;




$tabletActivityContainerHeight: 100%;
$tabletActivityContainerWidth: 220px;
$tabletInputContainerHeight: $desktopInputContainerHeight * 0.75;
$tabletInputContainerWidth: $tabletWidth - $tabletActivityContainerWidth;
$tabletChartContainerHeight: $tabletHeight - $tabletInputContainerHeight;
$tabletChartContainerWidth: $tabletInputContainerWidth;




$mobileInputContainerHeight: 100px;
$mobileInputContainerWidth: 100%;
$mobileChartContainerHeight: 230px;
$mobileChartContainerWidth: 100%;
$mobileActivityContainerHeight: 'fit-content';
$mobileActivityContainerWidth: 100%;

@import '../../styles/variables', './dark.module', './light.module', './classic.module', './minimalist.module';

@media screen and (min-width: $dMinW) {
  .container {
    height: 170px;
    min-width: 10%;
    padding-bottom: 16px;
    width: 100%;
  }

  .dotsWrapper {
    padding-bottom: 8px;
  }

  .dotWrapper {
    height: 20px;
    width: 20px;
  }

  .score {
    height: 9px;
    width: 9px;
  }

  .active {
    border-radius: 50%;
    height: 13px;
    width: 13px;
  }

  .wrapper {
    justify-content: center;
    min-height: 30px;
    text-align: center;
  }

  .toolTip {
    font-size: 12px;
    max-width: 200px;
    padding: 5px;
  }
}

// TABLET - scale by 75%
@media screen and (min-width: $tMinW) and (max-width: $tMaxW) {
  .container {
    height: 130px;
    padding-bottom: 6px;
    width: 100%;
  }

  .dotsWrapper {
    padding-bottom: 1px;
  }

  .active {
    border-radius: 50%;
    height: 11px;
    width: 11px;
  }

  .score {
    height: 8px;
    width: 8px;
  }

  .dotWrapper {
    height: 17px;
    width: 17px;
  }

  .wrapper {
    justify-content: center;
    min-height: 30px;
    text-align: center;
  }

  .toolTip {
    font-size: 11.5px;
    max-width: 185px;
    padding: 5px;
  }
}

// MOBILE
@media screen and (max-width: $mMaxW) {
  .container {
    flex-direction: row-reverse;
    font-size: 14px;
    height: 20px;
    min-height: 13%;
    width: 100%;
  }
  
  .dotsWrapper {
    flex-direction: row;
    height: 20px;
    justify-content: space-between;
    margin-left: 30px;
    width: 50%;
  }

  .score {
    height: 8px;
    width: 8px;
  }

  .active {
    border-radius: 50%;
    height: 12px;
    width: 12px;
  }

  .wrapper {
    justify-content: flex-start;
    letter-spacing: -0.6px;
  }

  .toolTip {
    font-size: 10.5px;
    max-width: 130px;
    padding: 5px;
  }
}

.container {
  align-items: center;
  justify-content: space-between;
}

.wrapper {
  max-width: 100%;
  text-overflow: wrap;
}

.dotWrapper {
  align-items: center;
  justify-content: center;
}

.score {
  border-radius: 50%;
}

.toolTip {
  text-align: center;
}

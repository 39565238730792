.dark {
  .tooltip {
    box-shadow: none;
  }
}

.classic.dark {
  color: $classicDarkWhite;

  .icon path {
    fill: $classicDarkWhite;
  }

  .icon:hover path {
    fill: $classicDarkRain;
  }

  .five {
    background-color: $classicDarkGreatGreen;
  }
    
  .four {
    background-color: $classicDarkGoodGreen;
  }

  .three {
    background-color: $classicDarkYellow;
  }

  .two {
    background-color: $classicDarkOrange;
  }

  .one {
    background-color: $classicDarkRed;
  }
}

.minimalist.dark {
  color: $minimalistDarkWhite;

  .icon path {
    fill: $minimalistDarkWhite;
  }

  .icon:hover path {
    fill: $minimalistDarkRain;
  }

  .five {
    background-color: $minimalistDarkGreatGreen;
  }
    
  .four {
    background-color: $minimalistDarkGoodGreen;
  }

  .three {
    background-color: $minimalistDarkYellow;
  }

  .two {
    background-color: $minimalistDarkOrange;
  }

  .one {
    background-color: $minimalistDarkRed;
  }
}

@import '../../styles/variables', './dark.module', './light.module';

.container {
  min-height: 100%;
  min-width: 10px;
  position: relative;
  right: 10px;
  top: 0;
  z-index: 3;
}

.thumb {
  border-radius: 10px;
  height: 50px;
  position: relative;
  width: 83%;
  z-index: 1;
}

@media screen and (min-width: $dMinW) {
  .classic {
    width: 20%;

    .container {
      font-size: 13px;
      letter-spacing: -0.4px;
    }
  }
}

@media screen and (min-width: $tMinW) and (max-width: $tMaxW) {
  .classic {
    width: 20%;

    .container {
      font-size: 11px;
      letter-spacing: -0.4px;
    }
  }
}

@media screen and (max-width: $mMaxW) {
  .classic {
    .wrapper {
      width: 80px;
    }
  }
}

.classic {
  .score {
    background-color: $lightTertiaryWhite;
  }
}

@import '../../styles/variables', './dark.module', './light.module', './classic.module', './minimalist.module';

@media screen and (min-width: $dMinW) {
  .classic,
  .minimalist,
  .container {
    height: $desktopInputContainerHeight;
    width: $desktopInputContainerWidth;
  }

  .icon {
    width: 26px;
    height: 26px;
    margin-left: 5px;    
    margin-top: 3px;
  }

  .wrapper {
    height: 35px;
    margin-top: 15px;
    align-items: flex-start;
  }

  .overlayWrapper {
    width: 200px;
    height: 32px;
    margin-right: 20px;
  }

  .disabled {
    opacity: .75;
  }
}

@media screen and (min-width: $tMinW) and (max-width: $tMaxW) {
  .classic,
  .minimalist,
  .container {
    height: $tabletInputContainerHeight;
    width: $tabletInputContainerWidth;
  }

  .icon {
    height: 22px;
    width: 22px;
    margin-left: 5px;
    margin-top: 3px;
  }

  .wrapper {
    height: 30px;
    margin-top: 10px;
    align-items: flex-start;
  }

  .overlayWrapper {
    width: 170px;
    height: 28px;
    margin-right: 20px;
  }

  .disabled {
    opacity: .75;
  }
}

@media screen and (max-width: $mMaxW) {
  .classic,
  .minimalist,
  .container {
    height: $mobileInputContainerHeight;
    width: $mobileInputContainerWidth;
  }

  .icon {
    height: 22px;
    width: 22px;
    margin-top: 3px;
    margin-left: 5px;
  }

  .wrapper {
    margin-top: 10px;
    height: 55px;
    display: flex;
    justify-content: space-between;
    display: -webkit-flex;
    -webkit-justify-content: space-between;
    -webkit-flex-wrap: wrap;
  }

  .overlayWrapper {
    width: 47%;
    height: 28px;
    margin: 0px auto;
  }

  .disabled {
    opacity: .75;
  }

  .header {
    padding-left: 10px;
  }
}

.disabled {
  cursor: auto;
}

.container {
  input {
    border: none;
    outline: none;
    padding-left: 30px;
    width: auto;
  }
}

.header {
  font-size: 16px;
}

.wrapper {
  display: flex;
  flex-direction: row;
}

.icon {
  position: absolute;
  z-index: 1;
}

.container input[type='submit'] {
  height: 0;
  margin: 0;
  max-width: 5px;
  padding: 0;
  visibility: hidden;
  width: 0px;
}

.overlayWrapper {
  border-radius: 5px;
}

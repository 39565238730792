@import '../../styles/variables';

@media screen and (min-width: $dMinW) {
  .container {
    height: 200px;
    width: 350px;
  }

  .glitch {
    font-size: 12px;
  }
}

@media screen and (min-width: $tMinW) and (max-width: $tMaxW) {
  .container {
    height: 165px;
    width: 290px;
  }

  .glitch {
    font-size: 10px;
  }
}

@media screen and (max-width: $mMaxW) {
  .container {
    height: 145px;
    margin-bottom: 60px;
    width: 80%;
  }

  .glitch {
    font-size: 10px;
  }
}

.dark {
  border: 15px solid $minimalistDarkSecondaryBlack;
}
  

.light {
  border: 15px solid $classicLightBlack;
}

.container {
  align-self: center;
  background-image: url('../../assets/ebs.jpg');
  background-size: contain;
  border-radius: 12px;
  margin: auto 10px;
  text-align: center;
}


.glitchWrapper {
  background-color: #000;
  margin: 50px 50px 0;
  padding: 3px 0;
}

.glitch {
  animation: text-skew 1s infinite linear alternate-reverse;
  color: #fff;
  font-family: 'Press Start 2P', serif;

  @mixin text-copy { 
    content: attr(data-text);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &::before {
    @include text-copy;
    animation: text-animation 5s infinite linear alternate-reverse;
    clip: rect(44px, 450px, 56px, 0);
    left: 3px;
    text-shadow: -2px 0 #00f;
  }

  &::after {
    @include text-copy;
    animation: text-animation 2s infinite linear alternate-reverse;
    left: -2px;
    text-shadow: -1px 0 #00f, 1px 2px #00f;
  }
}

@keyframes text-animation {
  $steps: 30;
  @for $i from 0 through $steps {
    #{percentage($i*(1/$steps))} {
      clip: rect(random(100)+px, 9999px, random(100)+px, 0);
      transform: skew((random(100) / 100) + deg);
    }
  }
}

@keyframes text-skew {
  $steps: 10;
  @for $i from 0 through $steps {
    #{percentage($i*(1/$steps))} {
      transform: skew((random(6) - 3) + deg);
    }
  }
}

@import './variables', './dayPicker.module', './apexCharts.module', './tooltip.module';

// Roboto
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');

// Caveat
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400;700&display=swap');

// Montserrat
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&display=swap');

// Josefin Slab
@import url('https://fonts.googleapis.com/css2?family=Josefin+Slab:wght@400&display=swap');

// Press Start 2P
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

// DESKTOP
@media screen and (min-width: $dMinW) {
  html,
  body {
    height: $desktopHeight + $htmlPadding*2;
    max-height: $desktopHeight + $htmlPadding*2;
    max-width: $desktopWidth + $htmlPadding*2;
    width: $desktopWidth + $htmlPadding*2;
  }

  body > div {
    height: $desktopHeight;
  }

  input {
    font-size: 14px;
    height: 30px;
    min-width: 25px;
  }
}

// TABLET - scale by 75%
@media screen and (min-width: $tMinW) and (max-width: $tMaxW) {
  html,
  body {
    height: $tabletHeight + $htmlPadding*2;
    max-height: $tabletHeight + $htmlPadding*2;
    max-width: $tabletWidth + $htmlPadding*2;
    width: $tabletWidth + $htmlPadding*2;
  }

  body > div {
    height: $tabletHeight + $htmlPadding*2;
  }

  input {
    font-size: 12px;
    height: 25px;
    min-width: 20px;
  }
}

// MOBILE
@media screen and (max-width: $mMaxW) {
  html,
  body {
    height: fit-content;
    margin: 3px;
    max-width: vw;
    width: 100%;
  }

  body > div {
    height: fit-content;
  }

  input {
    font-size: 12px;
    height: 25px;
    width: 50%;
  }
}

html {
  background: none;
  margin: auto;
}

body,
body > div {
  background: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
}

div {
  display: flex;
  flex-direction: column;
}

input {
  border-radius: 5px;
}

input[type='text'] {   
  appearance: none;
}

input:focus {
  outline: none;
}

// Black used in Satellite lottie: 0.19215686274509805

/* stylelint-disable selector-class-pattern */

.apexcharts-legend,
.apexcharts-legend-series {
  display: flex !important;
  flex-direction: row;
}

.apexcharts-svg {
  height: 100%;
}

.apexcharts-tooltip-title {
  align-items: center;
}

.apexcharts-tooltip-series-group,
.apexcharts-tooltip-text * {
  flex-direction: row;
}

.apexcharts-tooltip-text {
  margin: 0 2px;
}

.apexcharts-tooltip-series {
  flex-direction: column;
}

.apexcharts-xaxistooltip-bottom {
  display: none;
}

.apexcharts-tooltip-y-group {
  max-width: 160px;
}

.apexcharts-tooltip-y-group .apexcharts-tooltip-text-value {
  font-weight: 400;
  white-space: normal;
}

.apexcharts-tooltip-text-y-value {
  font-weight: 400;
  white-space: normal;
}

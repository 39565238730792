@import '../../styles/variables', './dark.module', './light.module', './classic.module', './minimalist.module';

$listItemPadding: 7px;

@media screen and (min-width: $dMinW) {
  .results {
    max-height: 300px;
    width: 250px + 2 * $listItemPadding;
  }

  .poweredDiv {
    height: 32px;
    min-height: 32px;
  }

  .poweredDiv img {
    height: 18px;
    width: 155px;
  }

  .overlayWrapper {
    min-height: 30px;
  }
}

@media screen and (min-width: $tMinW) and (max-width: $tMaxW) {
  .results {
    max-height: 250px;
    width: 225px + 2 * $listItemPadding;
  }

  .poweredDiv {
    height: 32px;
    min-height: 32px;
  }

  .poweredDiv img {
    height: 18px;
    width: 150px;
  }

  .overlayWrapper {
    min-height: 25px;
  }
}

@media screen and (max-width: $mMaxW) {
  .wrapper {
    min-height: fit-content;
    height: 100%;
  }

  .results {
    max-height: 400px;
    width: 150%;
  }

  .poweredDiv {
    height: 32px;
    min-height: 32px;
  }

  .poweredDiv img {
    height: 20px;
  }

  .overlayWrapper {
    min-height: 25px;
  }
}

.container,
.overlayWrapper,
.overlay {
  height: 100%;
  width: 100%;
  border-radius: 5px;
  cursor: text;
}

.results {
  height: fit-content;
  margin: 0;
  min-height: fit-content;
  z-index: 1;
}

.highlighted,
.prediction {
  cursor: pointer;
  display: inline-block;
  font-size: 10px;
  justify-content: center;
  max-height: 35px;
  min-height: 30px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  z-index: 2;
}

.highlighted,
.prediction,
.poweredDiv {
  padding: $listItemPadding;
  box-sizing: border-box;
  width: 100%;
}

.results .poweredDiv {
  align-items: flex-end;
  cursor: initial;
}

.poweredDiv {
  justify-content: center;
  z-index: 2;
}

.poweredDiv img {
  display: block;
  object-fit: none;
}

.mainText {
  font-weight: 500;
  margin-right: 3px;
}

// FADING 
.overlayWrapper {
  overflow: hidden;
  border-radius: 5px;
  position: relative;
}

.hideOverlay {
  display: none;
}

.overlay {
  position: absolute;
  z-index: 1;
}


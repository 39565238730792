/* stylelint-disable selector-class-pattern */

@media screen and (min-width: $dMinW) {
  .DayPickerInput,
  .DayPickerInput input {
    width: 168px;
  }
  
  .DayPickerInput-OverlayWrapper * {
    font-size: 14px;
  }

  .DayPicker-Weekday {
    height: 30px;
    min-height: 30px;
    min-width: 30px;
    width: 30px;
  }
}

@media screen and (min-width: $tMinW) and (max-width: $tMaxW) {
  .DayPickerInput,
  .DayPickerInput input {
    width: 138px;
  }

  .DayPickerInput-OverlayWrapper * {
    font-size: 12px;
  }

  .DayPicker-Weekday {
    height: 20px;
    min-height: 20px;
    min-width: 20px;
    width: 20px;
  }
}

@media screen and (max-width: $mMaxW) {
  .DayPickerInput {
    width: 100%;
    height: 100%;
    justify-content: center;
    display: flex;
  }

  .DayPickerInput-Overlay {
    height: 200px;
    left: initial;
    right: 0;
    width: 212px;
    height: auto;
  }

  .DayPickerInput-OverlayWrapper * {
    font-size: 12px;
  }

  .DayPicker-Weekday {
    height: 19px;
    min-height: 19px;
    min-width: 19px;
    width: 19px;
  }
}

.DayPickerInput,
.DayPickerInput input {
  border-radius: 10px;
}

.DayPicker-WeekdaysRow {
  flex-direction: row;
}

.DayPicker-Weekday {
  padding: 0;
  text-align: center;
  vertical-align: middle;
}

.DayPickerInput {
  border: none;
  width: 100%;
}

.light {
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    color: #fff;
  }  

  // calendar background color
  .DayPickerInput-Overlay {
    background-color: $lightPrimaryWhite;
  }
}

.dark {
  // hover background color
  .DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background-color: $darkTertiaryBlack;
  }

  // calendar background color
  .DayPickerInput-Overlay {
    background-color: $darkSecondaryBlack;
  }
}

.classic {
  .DayPickerInput-OverlayWrapper * {
    font-family: $classicFontFamily, sans-serif;
  }
}

.minimalist {
  .DayPickerInput-OverlayWrapper * {
    font-family: $minimalistFontFamily, sans-serif;
  }
}

.classic.light {
  // selected background color
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: $classicLightRain;
  }
}

.classic.dark {
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: $classicDarkRain;
  }
}

.minimalist.light {
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: $minimalistLightRain;
  }
}

.minimalist.dark {
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: $minimalistDarkRain;
  }
}

@import '../../styles/variables', './minimalist.module';

@media screen and (min-width: $dMinW) {
  .container {
    font-size: 10px;
    letter-spacing: -0.2px;
    margin-left: -15px;
  }

  .container div {
    margin: 0 5px 4px 0;
    max-width: 160px;
  }
}

@media screen and (min-width: $tMinW) and (max-width: $tMaxW) {
  .container {
    font-size: 8px;
    letter-spacing: -0.6px;
    margin-left: -15px;
  }

  .container div {
    margin: 0 0 2px;
    max-width: 100px;
  }
}

@media screen and (max-width: $mMaxW) {
  .container {
    align-items: center;
    flex-direction: column;
    font-size: 9px;
    justify-content: flex-end;
    letter-spacing: -0.6px;
  }
}

.container {
  flex-grow: 1;
  font-style: oblique;
  height: fit-content;
}

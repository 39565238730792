@import '../../styles/variables', './dark.module', './light.module', './classic.module', './minimalist.module';

$radius: 8px;
$scrollBuffer: 10px;

@media screen and (min-width: $dMinW) {
  $containerHeight: 290px;
  $itemHeight: 17px;

  .container {
    max-height: $containerHeight;
    padding: 3px 0;
  }

  .item {
    height: $itemHeight;
  }

  .select {
    max-height: $containerHeight - $itemHeight - $scrollBuffer;
  }

  .rendered {
    height: fit-content;
  }

  .container,
  .rendered,
  .notRendered {
    width: 100px;
  }

  .activity {
    line-height: 105%;
    width: 100%;
  }
}

// TABLET - scale by 75%
@media screen and (min-width: $tMinW) and (max-width: $tMaxW) {
  $containerHeight: 240px;
  $itemHeight: 15px;
  
  .container {
    max-height: $containerHeight;
    padding: 1px 0;
  }

  .item {
    height: $itemHeight;
  }

  .select {
    max-height: $containerHeight - $itemHeight - $scrollBuffer;
  }

  .container,
  .rendered,
  .notRendered {
    width: 105px;
  }

  .activity {
    line-height: 100%;
    padding: 0;
    width: 80%;
  }

  .item {
    font-size: 12px;
  }
}

// MOBILE
@media screen and (max-width: $mMaxW) {
  $containerHeight: 200px;
  $itemHeight: 15px;

  .container {
    max-height: $containerHeight;
    padding: 1px 0;
  }


  .item {
    height: $itemHeight;
  }

  .select {
    max-height: $containerHeight - $itemHeight - $scrollBuffer;
  }

  .container,
  .rendered,
  .notRendered {
    width: 150px;
  }

  .activity {
    line-height: 105%;
    width: 100%;
  }

  .item {
    font-size: 14px;
  }
}

.container {
  cursor: pointer;
  flex-direction: column;
  flex-grow: 1;
}

.notRendered {
  border-radius: $radius;
}

.rendered {
  border-radius: $radius;
}

.activity {
  background-color: inherit;
  border: none;
  cursor: pointer;
  display: block;
  max-height: 25px;
  outline: none;
  overflow: hidden;
  padding-left: 3px;
  text-align: left;
  text-overflow: ellipsis;
}

.activity:active {
  color: $classicLightBlack;
}

.inputWrapper {
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  min-height: 25px;
}

.select {
  border-top: none;
  cursor: pointer;
  height: 100%;
  text-align: left;
}

.select:focus {
  outline: 0;
}

.item {
  display: block;
  height: fit-content;
  min-height: 15px;
  overflow: hidden;
  padding: 10px 2px 10px 6px;
  text-overflow: ellipsis;
}

.icon {
  height: 20px;
  justify-content: center;
  margin-right: 3px;
  overflow: hidden;
  width: 25px;
}

.collapse {
  transform: scaleY(-1);
}

.selectContainer {
  flex-direction: row;
  overflow: hidden;
}

.selectWrapper {
  flex-grow: 1;
  min-width: 100%;
  overflow: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.selectWrapper::-webkit-scrollbar {
  display: none;
}
